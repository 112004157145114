import { AxiosError } from 'axios';
import { ITrade } from 'api/trades';
import {
  CLEAR_ONE_TRADE,
  GET_ONE_TRADE_START,
  GET_ONE_TRADE_SUCCESS,
  GET_ONE_TRADE_FAILURE,
  UPDATE_ONE_TRADE,
} from '../actions/oneTradeActions';
import { IAdditionalApiStatuses } from './slices/interfaces';

interface Items {
  items: ITrade[],
}

const initialState: Items & IAdditionalApiStatuses = {
  items: [],
  loading: true,
  loaded: false,
  error: null,
};

interface StartSuccses {
  type: typeof GET_ONE_TRADE_START;
}
interface GetAction {
  type: typeof GET_ONE_TRADE_SUCCESS;
  payload: {
    results: ITrade[];
  };
}
interface Failing {
  type: typeof GET_ONE_TRADE_FAILURE;
  payload: {
    error: AxiosError;
  };
}
interface Update {
  type: typeof UPDATE_ONE_TRADE;
  payload: {
    data: ITrade;
    tradeId: number;
  };
}
interface Clear {
  type: typeof CLEAR_ONE_TRADE;
}

type Action = StartSuccses | GetAction | Failing | Update | Clear;

export default function oneTradeReducer(state = initialState, action: Action): typeof initialState {
  switch (action.type) {
    case GET_ONE_TRADE_START:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };
    case GET_ONE_TRADE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        items: action.payload.results,
      };
    case GET_ONE_TRADE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload.error,
      };
    case UPDATE_ONE_TRADE:
      return {
        ...state,
        items: state.items.map(item =>
          item.id === action.payload.tradeId
            ? {
              ...item,
              ...action.payload.data,
            }
            : item,
        ),
      };
    case CLEAR_ONE_TRADE:
      return initialState;
    default:
      return state;
  }
}
