import { LotType } from 'constants/general';
import { AnyTODO } from 'core/interfaces';

export const SCOPE_CHAT = 'Chat';
export const SCOPE_CHAT_TRADE_LIST = 'ChatTradeList' as const;

type NestedScopeRoot<T> = {
  [K in keyof T]: T[K] extends string
    ? K | [K]
    : NestedScopeChildren<T>
}[keyof T];

type NestedScopeChildren<T> = {
  [K in keyof T]: T[K] extends Record<string, AnyTODO>
    ? [K, ...NestedScopeChildren<T[K]>]
    : [K];
}[keyof T];

export type ScopeChatTradeListType = NestedScopeRoot<typeof uk[typeof SCOPE_CHAT_TRADE_LIST]>;
export type ScopeChatType = NestedScopeRoot<typeof uk>;
export const uk = {
  tradeTypes: {
    softOffer: 'Ціна продажу',
    softRequest: 'Ціна закупівлі',
    hardOffer: 'Тверда пропозиція продажу',
    hardRequest: 'Тверда пропозиція закупівлі',
  },
  tabs: {
    list: 'Торги',
    chat: 'Чат',
    info: 'Лот',
  },
  Message: {
    softOffer: `$t(${SCOPE_CHAT}$tradeTypes$softOffer) від {{company}}`,
    hardOffer: `$t(${SCOPE_CHAT}$tradeTypes$hardOffer) від {{company}}`,
    softRequest: `$t(${SCOPE_CHAT}$tradeTypes$softRequest) від {{company}}`,
    hardRequest: `$t(${SCOPE_CHAT}$tradeTypes$hardRequest) від {{company}}`,
    nothing: '{{company}}',
    priceConfirmed: 'Ціна підтверджена',
    businessConfirmationBy: 'Бізнес підтвердження від {{company}}',
    closedBy: 'Закрито, {{company}}',
    buttons: {
      offer_price: 'Запропонувати ціну',
      accept_price: 'Прийняти пропозицію',
      confirm_offer: 'Підтвердити пропозицію',
      leave_trade: 'Вийти з торгів',
    },
    text: {
      trade_start: 'Торг відкритий, можете його продовжити або вийти',
      confirmation: 'Ставка підтверджена компанією {{company}}',
      accept_price: `
        <p>
          Підтвердіть запропоновану вам ціну. Зверніть увагу: після підтверження пропозиції ви не матимете можливості від неї відмовитися, якщо тільки інша сторона не відхилить її або не закінчиться час для надання пропозиції.
        </p>
        <p>
          Після підтверження, ви не зможете зробити інші тверді пропозиції щодо даного лота іншим учасникам торгів, але ви зможете отримувати тверді пропозиції від них.
        </p>
      `,
      confirm_offer: 'Підтвердження твердої ставки означатиме згоду двох сторін на остаточні торги та ініціює процес укладання Угоди',
    },
    types: {
      confirmation: 'Підтвердження',
      trade_closed: '',
      bid_changed: 'Зміна ставки',
      trade_start: '',
    },
    infoPopups: {
      emptyMessage: 'Нічого відправляти',
      errorSend: 'Не вдалося відправити повідомлення',
      exceedLength: 'Перевищено ліміт сімвлов ({{count}}/{{MAX_CHAT_MESSAGE_LENGTH}})',
    },
    placeholders: {
      send_message: 'Відправити повідомлення',
    },
  },
  SuggestPrice: {
    AcceptCheckBox: {
      text: 'Для продовження, підтвердіть, будь ласка, свою згоду/ознайомлення',
    },
    tabs: {
      [LotType.offer]: {
        soft_price: `$t(${SCOPE_CHAT}$tradeTypes$softOffer)`,
        hard_bid: `$t(${SCOPE_CHAT}$tradeTypes$hardOffer)`,
      },
      [LotType.request]: {
        soft_price: `$t(${SCOPE_CHAT}$tradeTypes$softRequest)`,
        hard_bid: `$t(${SCOPE_CHAT}$tradeTypes$hardRequest)`,
      },
    },
    valid_until: 'Дійсно до',
    agreedWithHardBid: 'Прийняти',
    softBidInfo: `
      <p>
        Запропонуйте свою ціну. Дана ціна є необов'язковою для виконання та носить індикативний характер. Інша сторона матиме можливість зробити зустрічне індикативне пропозицію або надати тверду пропозицію у вигляді бід- або офер-пропозиції.
      </p>
      <p>
        У разі надання іншою стороною твердої пропозиції, вам буде запропоновано зробити одне з наступного: надати зустрічну тверду пропозицію, прийняти чинну пропозицію або вийти з торгів.
      </p>
    `,
    hardBidInfo: `
      <p>
        Надати тверду пропозицію. Зверніть увагу: після надання твердої пропозиції ви не матимете можливості від неї відмовитися, якщо тільки інша сторона не відхилить її або не закінчиться час для надання пропозиції.
      </p>
      <p>
        Після надання твердої пропозиції, ви не зможете зробити інші тверді пропозиції щодо даного лота іншим учасникам торгів, але ви зможете отримувати тверді пропозиції від них.
      </p>
    `,
  },
  [SCOPE_CHAT_TRADE_LIST]: {
    price: 'Ціна',
    tooltips: {
      lastMessageTime: 'Останнє повідомлення',
      messagesCount: 'Загальна кількість повідомлень',
      unreadCount: 'Непрочитані повідомлення',
    },
  },
};

export const en: typeof uk = {
  tradeTypes: {
    softOffer: 'Seller\'s price',
    softRequest: 'Buyer\'s price',
    hardOffer: 'Seller\'s offer',
    hardRequest: 'Buyer\'s bid',
  },
  tabs: {
    list: 'Trades',
    chat: 'Chat',
    info: 'Lot',
  },
  Message: {
    softOffer: `$t(${SCOPE_CHAT}$tradeTypes$softOffer) from {{company}}`,
    hardOffer: `$t(${SCOPE_CHAT}$tradeTypes$hardOffer) from {{company}}`,
    softRequest: `$t(${SCOPE_CHAT}$tradeTypes$softRequest) from {{company}}`,
    hardRequest: `$t(${SCOPE_CHAT}$tradeTypes$hardRequest) from {{company}}`,
    nothing: '{{company}}',
    priceConfirmed: 'Цена подтверджена',
    businessConfirmationBy: 'Business confirmation from {{company}}',
    closedBy: 'Closed by {{company}}',
    buttons: {
      offer_price: 'Offer price',
      accept_price: 'Accept offer',
      confirm_offer: 'Confirm offer',
      leave_trade: 'Leave trade',
    },
    text: {
      trade_start: 'The trade is open, you can continue or exit',
      confirmation: 'Trade has confirmed by {{company}}',
      accept_price: `
        <p>
          Confirm the price offered to you. It is important that after confirmation you will not be able to refuse it, only if the other party refuses to re-confirm it or the bidding time expires.
        </p>
        <p>
          You will also not be able to make a firm offer for this lot to other bidders during the validity of this confirmation, but you will be able to receive hard offers from them.
        </p>
      `,
      confirm_offer: 'Confirmation of a hard bid will signify the agreement of both parties to the final bidding and initiate the process of concluding the Deal',
    },
    types: {
      confirmation: 'confirmation',
      trade_closed: '',
      bid_changed: 'bid_changed',
      trade_start: '',
    },
    infoPopups: {
      emptyMessage: 'Nothing to send',
      errorSend: 'Failed to send message',
      exceedLength: 'Exceeded character limit ({{count}}/{{MAX_CHAT_MESSAGE_LENGTH}})',
    },
    placeholders: {
      send_message: 'Send message',
    },
  },
  SuggestPrice: {
    AcceptCheckBox: {
      text: 'To continue, please confirm your consent/acknowledgment',
    },
    tabs: {
      [LotType.offer]: {
        soft_price: `$t(${SCOPE_CHAT}$tradeTypes$softOffer)`,
        hard_bid: `$t(${SCOPE_CHAT}$tradeTypes$hardOffer)`,
      },
      [LotType.request]: {
        soft_price: `$t(${SCOPE_CHAT}$tradeTypes$softRequest)`,
        hard_bid: `$t(${SCOPE_CHAT}$tradeTypes$hardRequest)`,
      },
    },
    valid_until: 'Valid until',
    agreedWithHardBid: 'Accept',
    softBidInfo: `
      <p>
        Offer your price. This price is optional for execution and is indicative in nature. The other party will have the opportunity to make a counter-indicative offer or provide a firm offer in the form of a bid or offer.
      </p>
      <p>
        If the other party makes a firm offer, you will be prompted to do one of the following: make a counter-firm offer, accept the current offer, or withdraw from the auction.
      </p>
    `,
    hardBidInfo: `
      <p>
        Provide a hard offer. Please note that once you have made a hard offer, you will not be able to leave it unless the other party rejects it or the time to make the offer expires.
      </p>
      <p>
        Once you have made a firm offer, you will not be able to make other firm offers on that lot to other bidders, but you will be able to receive firm offers from them.
      </p>
    `,
  },
  [SCOPE_CHAT_TRADE_LIST]: {
    price: 'Price',
    tooltips: {
      lastMessageTime: 'Last message',
      messagesCount: 'Total number of messages',
      unreadCount: 'Unread messages',
    },
  },
};

export const ru: typeof uk = {
  tradeTypes: {
    softOffer: 'Цена продажи',
    softRequest: 'Цена закупки',
    hardOffer: 'Твёрдое предложение продажи',
    hardRequest: 'Твёрдое предложение закупки',
  },
  tabs: {
    list: 'Торги',
    chat: 'Чат',
    info: 'Лот',
  },
  Message: {
    softOffer: `$t(${SCOPE_CHAT}$tradeTypes$softOffer) от {{company}}`,
    hardOffer: `$t(${SCOPE_CHAT}$tradeTypes$hardOffer) от {{company}}`,
    softRequest: `$t(${SCOPE_CHAT}$tradeTypes$softRequest) от {{company}}`,
    hardRequest: `$t(${SCOPE_CHAT}$tradeTypes$hardRequest) от {{company}}`,
    nothing: '{{company}}',
    priceConfirmed: 'Цена подтверджена',
    businessConfirmationBy: 'Бизнес подтверждение от {{company}}',
    closedBy: 'Закрыто, {{company}}',
    buttons: {
      offer_price: 'Предложить цену',
      accept_price: 'Принять предложение',
      confirm_offer: 'Подтвердить предложение',
      leave_trade: 'Выйти из торгов',
    },
    text: {
      trade_start: 'Торг открыт, можете его продолжить или выйти',
      confirmation: 'Ставка подтверждена компанией {{company}}',
      accept_price: `
        <p>
          Подтвердите предложенную вам цену. Важно, после подтверждения вы не сможете от него отказаться, только в случае отказа второй стороны перепдотвердить его или время торгов истечет.
        </p>
        <p>
          Так же вы не сможете сделать твердое предложение по данному лоту другим учасникам торгов, во время действия этого подтверждения, но сможете получать от них твердые предложени.
        </p>
      `,
      confirm_offer: 'Подтверждение твёрдой ставки, будет означать согласие двух сторон на окончательные торги и инициирует процесс заключения Сделки',
    },
    types: {
      confirmation: 'Подтверждение',
      trade_closed: '',
      bid_changed: 'Изменение ставки',
      trade_start: '',
    },
    infoPopups: {
      emptyMessage: 'Нечего отправлять',
      errorSend: 'Не удалось отправить сообщение',
      exceedLength: 'Превышен лимит симвлов ({{count}}/{{MAX_CHAT_MESSAGE_LENGTH}})',
    },
    placeholders: {
      send_message: 'Отправить сообщение',
    },
  },
  SuggestPrice: {
    AcceptCheckBox: {
      text: 'Для продолжения, подтвердите, пожалуйста, своё согласие/ознакомление',
    },
    tabs: {
      [LotType.offer]: {
        soft_price: `$t(${SCOPE_CHAT}$tradeTypes$softOffer)`,
        hard_bid: `$t(${SCOPE_CHAT}$tradeTypes$hardOffer)`,
      },
      [LotType.request]: {
        soft_price: `$t(${SCOPE_CHAT}$tradeTypes$softRequest)`,
        hard_bid: `$t(${SCOPE_CHAT}$tradeTypes$hardRequest)`,
      },
    },
    valid_until: 'Действительно до',
    agreedWithHardBid: 'Принять',
    softBidInfo: `
      <p>
        Предложите свою цену. Данная цена необязательна для выполнения и носит индикативный характер. Другая сторона сможет сделать встречное индикативное предложение или предоставить твердое предложение в виде бид- или офер-предложения.
      </p>
      <p>
        В случае предоставления другой стороной твердого предложения вам будет предложено сделать одно из следующего: предоставить встречное твердое предложение, принять действующее предложение или выйти из торгов.
      </p>
    `,
    hardBidInfo: `
      <p>
        Предоставить твердое предложение. Обратите внимание: после предоставления твердого предложения вы не сможете отказаться от него, если только другая сторона не отклонит его или не истечет время для предоставления предложения.
      </p>
      <p>
        После предоставления твердого предложения вы не сможете сделать другие твердые предложения по данному лоту другим участникам торгов, но вы сможете получать твердые предложения от них.
      </p>
    `,
  },
  [SCOPE_CHAT_TRADE_LIST]: {
    price: 'Цена',
    tooltips: {
      lastMessageTime: 'Последнее сообщение',
      messagesCount: 'Общее количество сообщений',
      unreadCount: 'Непрочитанные сообщения',
    },
  },
};

export const bg: typeof uk = {
  tradeTypes: {
    softOffer: 'Продажна цена',
    softRequest: 'Покупна цена',
    hardOffer: 'Твърда оферта за продажба',
    hardRequest: 'Твърда оферта за покупка',
  },
  tabs: {
    list: 'Наддаване',
    chat: 'Чат',
    info: 'Лот',
  },
  Message: {
    softOffer: `$t(${SCOPE_CHAT}$tradeTypes$softOffer) от {{company}}`,
    hardOffer: `$t(${SCOPE_CHAT}$tradeTypes$hardOffer) от {{company}}`,
    softRequest: `$t(${SCOPE_CHAT}$tradeTypes$softRequest) от {{company}}`,
    hardRequest: `$t(${SCOPE_CHAT}$tradeTypes$hardRequest) от {{company}}`,
    nothing: '{{company}}',
    priceConfirmed: 'Цената е потвърдена',
    businessConfirmationBy: 'Бизнес потвърждение от {{company}}',
    closedBy: 'Закрито, {{company}}',
    buttons: {
      offer_price: 'Предложи цена',
      accept_price: 'Приеми предложение',
      confirm_offer: 'Потвърдете предложение',
      leave_trade: 'Излез от търговията',
    },
    text: {
      trade_start: 'Търгът е отворен, можете да го продължите или да излезете',
      confirmation: 'Цената е потвърдена от компанията {{company}}',
      accept_price: `
        <p>
          Потвърдете предложената ви цена. Важно е, че след потвърждение няма да можете да го откажете, само ако втората страна откаже да го потвърди отново или времето за търговия изтече.
        </p>
        <p>
          Освен това няма да можете да правите твърда оферта за този лот на други оференти по време на валидността на това потвърждение, но ще можете да получавате твърди оферти от тях.
        </p>
      `,
      confirm_offer: 'Потвърждението на твърда оферта ще означава съгласието на двете страни за окончателното наддаване и инициира процеса на сключване на транзакция',
    },
    types: {
      confirmation: 'Потвърждение',
      trade_closed: '',
      bid_changed: 'Промяна на офертата',
      trade_start: '',
    },
    infoPopups: {
      emptyMessage: 'Няма какво да изпратите',
      errorSend: 'Неуспешно изпращане на съобщението',
      exceedLength: 'Превишен лимит на символите ({{count}}/{{MAX_CHAT_MESSAGE_LENGTH}})',
    },
    placeholders: {
      send_message: 'Изпрати съобщение',
    },
  },
  SuggestPrice: {
    AcceptCheckBox: {
      text: 'За да продължите, моля, потвърдете вашето съгласие/четене',
    },
    tabs: {
      [LotType.offer]: {
        soft_price: `$t(${SCOPE_CHAT}$tradeTypes$softOffer)`,
        hard_bid: `$t(${SCOPE_CHAT}$tradeTypes$hardOffer)`,
      },
      [LotType.request]: {
        soft_price: `$t(${SCOPE_CHAT}$tradeTypes$softRequest)`,
        hard_bid: `$t(${SCOPE_CHAT}$tradeTypes$hardRequest)`,
      },
    },
    valid_until: 'Валидна до',
    agreedWithHardBid: 'Приемам',
    softBidInfo: `
      <p>
        Предложете своята цена. Тази цена не е задължителна за изпълнение и има ориентировъчен характер. Другата страна ще има възможност да направи контраиндикативна оферта или да предостави твърда оферта под формата на бид или оферта.
      </p>
      <p>
        Ако другата страна направи твърда оферта, ще бъдете подканени да направите едно от следните: да направите насрещна твърда оферта, да приемете текущата оферта или да се оттеглите от търга.
      </p>
    `,
    hardBidInfo: `
      <p>
        Дайте твьрда оферта. Моля, имайте предвид, че след като сте направили твърда оферта, няма да можете да я оттеглите, освен ако другата страна не я отхвърли или времето за отправяне на офертата изтече.
      </p>
      <p>
        След като сте направили твърда оферта, няма да можете да правите други твърди оферти за тази партида на други наддавачи, но ще можете да получавате твърди оферти от тях.
      </p>
    `,
  },
  [SCOPE_CHAT_TRADE_LIST]: {
    price: 'Цена',
    tooltips: {
      lastMessageTime: 'Последно съобщение',
      messagesCount: 'Общо брой съобщения',
      unreadCount: 'Непрочетени съобщения',
    },
  },
};
