import { CompanyHandshakeSubStatus } from 'models/CompanyHandshake';
import { NOTIFICATION_TYPES } from 'modules/Notifications/NotificationsItem';

export const SCOPE_NOTIFICATIONS = 'Notifications';

export const uk = {
  [NOTIFICATION_TYPES.company_handshake]: {
    titles: {
      [CompanyHandshakeSubStatus.none]: 'Відміна рукостискання',
      [CompanyHandshakeSubStatus.my]: 'Запит на рукостискання',
      [CompanyHandshakeSubStatus.full]: 'Підтвердження рукостискання',
    },
    description: 'від {{company}}',
  },
  [NOTIFICATION_TYPES.trade]: {
    titles: {
      request: {
        soft_bid: 'Ціна закупівлі',
        hard_bid: 'Тверда пропозиція закупівлі',
        received: 'Отримано запит',
        confirmed: 'Підтвердження покупки',
        refused: 'Відмова від покупки',
        changed: 'Дані покупки були змінені',
        expired: 'Термін дії продажу минув',
      },
      offer: {
        soft_bid: 'Ціна продажу',
        hard_bid: 'Тверда пропозиція продажу',
        received: 'Отримано пропозицію',
        confirmed: 'Підтвердження продажу',
        refused: 'Відмова від продажу',
        changed: 'Дані продажу були змінені',
        expired: 'Термін дії продажу минув',
      },
    },
    description: 'від {{company}}. {{culture}}. Лот №{{lotId}}',
  },
};

export const en: typeof uk = {
  [NOTIFICATION_TYPES.company_handshake]: {
    titles: {
      [CompanyHandshakeSubStatus.none]: 'Revoking Handshake',
      [CompanyHandshakeSubStatus.my]: 'Requesting Handshake',
      [CompanyHandshakeSubStatus.full]: 'Confirming Handshake',
    },
    description: 'from {{company}}',
  },
  [NOTIFICATION_TYPES.trade]: {
    titles: {
      request: {
        soft_bid: 'Buyer\'s price',
        hard_bid: 'Buyer\'s bid',
        received: 'Request has received',
        confirmed: 'Purchase confirmation',
        refused: 'Refused purchase',
        changed: 'Request has been changed',
        expired: 'Offer has expired',
      },
      offer: {
        soft_bid: 'Seller\'s price',
        hard_bid: 'Seller\'s offer',
        received: 'Offer has received',
        confirmed: 'Sale confirmation',
        refused: 'Refused sale',
        changed: 'Request has been changed',
        expired: 'Offer has expired',
      },
    },
    description: 'from {{company}}. {{culture}}. Lot #{{lotId}}',
  },
};

export const ru: typeof uk = {
  [NOTIFICATION_TYPES.company_handshake]: {
    titles: {
      [CompanyHandshakeSubStatus.none]: 'Отмена рукопожатия',
      [CompanyHandshakeSubStatus.my]: 'Запрос на рукопожатие',
      [CompanyHandshakeSubStatus.full]: 'Подтверждение рукопожатия',
    },
    description: 'от {{company}}',
  },
  [NOTIFICATION_TYPES.trade]: {
    titles: {
      request: {
        soft_bid: 'Цена закупки',
        hard_bid: 'Твёрдое предложение закупки',
        received: 'Получен запрос',
        confirmed: 'Подтверждение покупки',
        refused: 'Отказ покупки',
        changed: 'Данные покупки были изменены',
        expired: 'Cрок действия продажи истек',
      },
      offer: {
        soft_bid: 'Цена продажи',
        hard_bid: 'Твёрдое предложение продажи',
        received: 'Полученo предложение',
        confirmed: 'Подтверждение продажи',
        refused: 'Отказ от продажи',
        changed: 'Данные продажи были изменены',
        expired: 'Cрок действия продажи истек',
      },
    },
    description: 'от {{company}}. {{culture}}. Лот №{{lotId}}',
  },
};

export const bg: typeof uk = {
  [NOTIFICATION_TYPES.company_handshake]: {
    titles: {
      [CompanyHandshakeSubStatus.none]: 'Извикване на ръкостискане',
      [CompanyHandshakeSubStatus.my]: 'Искане за ръкостискане',
      [CompanyHandshakeSubStatus.full]: 'Потвърждение на ръкостискане',
    },
    description: 'от {{company}}',
  },
  [NOTIFICATION_TYPES.trade]: {
    titles: {
      request: {
        soft_bid: 'Покупна цена',
        hard_bid: 'Твърда оферта за покупка',
        received: 'Заявката е получена',
        confirmed: 'Потвърждение на покупката',
        refused: 'Отказана покупка',
        changed: 'Данните покупки бяха изменени',
        expired: 'Продажбата е изтекла',
      },
      offer: {
        soft_bid: 'Продажна цена',
        hard_bid: 'Твърда оферта за продажба',
        received: 'Офертата е получена',
        confirmed: 'Потвърждение на продажба',
        refused: 'Отказана продажба',
        changed: 'Данните продажбите бяха изменени',
        expired: 'Продажбата е изтекла',
      },
    },
    description: 'от {{company}}. {{culture}}. Лот №{{lotId}}',
  },
};
