import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { SvgNotificationsWarningIcon, SvgTickIcon, SvgUploadCubeIcon,
  SvgDownloadCubeIcon, SvgDownloadIcon, SvgNotificationsCloseIcon } from 'components/Icons';
import { HandshakeIcon } from 'modules/Cabinet/HandshakedCompanies/HandshakeIcon';
import { readNotificationsApi } from 'api/notificationsApi';
import { ROUTES } from 'constants/routes';
import { INotification } from 'reducers/notificationsReducer';
import { DateElement } from 'components/DateElement';
import { Company } from 'models/Company';
import { Culture } from 'models/Culture';
import { useT } from 'hooks';
import { SCOPE_NOTIFICATIONS } from 'i18n/structure/modules/Notifications/Notifications';
import { CompanyHandshake } from 'models/CompanyHandshake';

const LEVEL_TYPE = {
  attention: 'attention',
  success: 'success',
  info: 'info',
  warning: 'warning',
  error: 'error',
};

export enum NOTIFICATION_TYPES {
  offer = 'offer',
  request = 'request',
  trade = 'trade',
  company = 'company',
  CompanyInvite = 'CompanyInvite',
  company_handshake = 'company_handshake',
}
export enum NOTIFICATION_TRADE_TYPES {
  soft_bid = 'soft_bid',
  hard_bid = 'hard_bid',
  received = 'received',
  confirmed = 'confirmed',
  refused = 'refused',
  changed = 'changed',
  expired = 'expired',
}


interface Props {
  item: INotification;
  onClick?: () => void;
}

export const NotificationsItem: React.FC<Props> = ({ item, onClick }) => {
  const _t = useT(SCOPE_NOTIFICATIONS);
  const { push } = useHistory();
  const dispatch = useDispatch();
  const requestClass = item.data.type === 'request' || item.data.type === 'offer' ? 'request' : '';
  const unreadClass = item.unread ? requestClass : 'read';
  const notificationsIcons = {
    [LEVEL_TYPE.attention]: <SvgNotificationsWarningIcon />,
    [LEVEL_TYPE.success]: <SvgTickIcon />,
    [LEVEL_TYPE.info]:
      item.data.type === 'request' ?
        <SvgDownloadCubeIcon />
        : item.data.type === 'offer' ?
          <SvgUploadCubeIcon />
          :
          <SvgDownloadIcon />
    ,
    [LEVEL_TYPE.warning]: <SvgNotificationsWarningIcon />,
    [LEVEL_TYPE.error]: <SvgNotificationsCloseIcon />,
  };


  const goToPage = () => {
    switch (item.data.item) {
      case NOTIFICATION_TYPES.offer:
      case NOTIFICATION_TYPES.request:
        push(`${ROUTES.proposals}${item.data.item}/${item.data.id}/`);
        break;
      case 'company':
        push(ROUTES.settings.company);
        break;
      case 'CompanyInvite':
        push(ROUTES.settings[item.data.target === 'owner' ? 'users' : 'invites']);
        break;
      case NOTIFICATION_TYPES.trade:
        push(`${ROUTES.chat}?tradeId=${item.data.Item.id}`);
        break;
      case NOTIFICATION_TYPES.company_handshake:
        Company.find(item.data.Item.company_id as number).goTo();
        break;
      default:
        // replace(ROUTES.notFound);
        break;
    }
    onClick?.();
    dispatch(readNotificationsApi([item.id], false));
  };

  const title = item.data.item === NOTIFICATION_TYPES.company_handshake
    ? _t([NOTIFICATION_TYPES.company_handshake, 'titles', new CompanyHandshake(item.data.Item).subStatusWithoutTime])
    : item.data.item === NOTIFICATION_TYPES.trade && item.data.Item
      ? _t([NOTIFICATION_TYPES.trade, 'titles', item.data.Item.lot_type_reason, item.data.type])
      : item.title;
  const description = item.data.item === NOTIFICATION_TYPES.company_handshake
    ? _t([NOTIFICATION_TYPES.company_handshake, 'description'], { company: Company.find(item.data.Item.company_id || 0).name })
    : item.data.item === NOTIFICATION_TYPES.trade && item.data.Item
      ? _t([NOTIFICATION_TYPES.trade, 'description'], {
        company: Company.find(item.data.company_id).name,
        culture: Culture.find(item.data.Item.lot.cultrue_id).name,
        lotId: item.data.Item.lot.id,
      })
      : item.description;

  const icon = item.data.type === NOTIFICATION_TYPES.company_handshake
    ? <HandshakeIcon handshake={item.data.Item} />
    : notificationsIcons[item.level];

  return (
    <div
      className={`notifications__item ${unreadClass} ${item.level}`}
      onClick={goToPage}
    >
      <div className="notifications__icon-block">
        {icon}
      </div>
      <div className="notifications__body">
        <div className={`notifications__title ${item.level} ${requestClass}`}>
          {title}
        </div>
        <div className="notifications__description">
          {description}
        </div>
      </div>
      <div className="notifications__time">
        <DateElement datetime={item.created_at} />
      </div>
    </div>
  );
};
