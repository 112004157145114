import { API } from 'modules/Api';
import Logger from 'modules/Logger';
import { LIST } from 'models/all';
import { TsThunk } from 'store/store.types';
import { AnyTODO } from 'core/interfaces/AnyTODO.type';

const TYPES = {
  GET: 'GET_ALL',
  FAILED: 'FAILED_ALL',
  LOADING: 'LOADING_ALL',
} as const;
export default TYPES;

export const getAll = (force = false): TsThunk => (dispatch, getState) => {
  if (getState().all.loading || (!force && getState().all.loaded)) {
    return;
  }

  dispatch({ type: TYPES.LOADING, payload: true });

  Promise.all(LIST.map(model => API.get(`${model.api}?limit=9999`)))
    .then(result => {
      dispatch({
        type: TYPES.GET,
        payload: LIST.reduce<AnyTODO>((acc, model, index) => (acc[model.plural] = result[index].data, acc), {}),
      });
    })
    .catch(result => {
      dispatch({ type: TYPES.FAILED, payload: true });
      Logger.error(result);
    })
    .finally(() => dispatch({ type: TYPES.LOADING, payload: false }));
};
