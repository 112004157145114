import { TradeStatus } from 'constants/general';
import { SCOPE_CHAT } from 'i18n/Chat';
import { InitiatorTypeEnum } from 'models/trade';


export const SCOPE_TRADES = 'Trades';
export const SCOPE_TRDADES_COLUMNS = `${SCOPE_TRADES}.columns`;


export const uk = {
  title: 'Мої торги',
  trade: 'Торг',
  columns: {
    trade: 'Торг',
    culture: 'Продукт',
    type: 'Тип',
    info: 'Інформація',
    origin: 'Походження',
    company: 'Компанія',
    // country: 'Країна',
    // openType: 'Ініціатор',
    status: 'Статус',
    validUntil: 'Діє до',
    unread: '',
  },
  BadgeConfirmation: {
    closed: '{{company}}: Закрито',
    nothing: '{{company}}',
    softOffer: `{{company}}: $t(${SCOPE_CHAT}$tradeTypes$softOffer)`,
    softRequest: `{{company}}: $t(${SCOPE_CHAT}$tradeTypes$softRequest)`,
    hardOffer: `{{company}}: $t(${SCOPE_CHAT}$tradeTypes$hardOffer)`,
    hardRequest: `{{company}}: $t(${SCOPE_CHAT}$tradeTypes$hardRequest)`,
  },
  initiatorType: {
    [InitiatorTypeEnum.got]: 'Отриман',
    [InitiatorTypeEnum.opened]: 'Відкрит',
  },
  statuses: {
    [TradeStatus.closed]: 'Закрит',
    [TradeStatus.confirmed]: 'Підтверджен',
    [TradeStatus.open]: '-',
  },
  filters: {
    all: 'Все',
    open: 'Відкриті',
    received: 'Отримані',
    confirmed: 'Підтверджені',
    closed: 'Закриті',
    allCompanyTrades: 'Всій Компанії',
  },
  tooltips: {
    [InitiatorTypeEnum.got]: 'Отриманий торг',
    [InitiatorTypeEnum.opened]: 'Створений торг',
  },
  messages: {
    successClosed: 'Торг закритий',
  },
  buttons: {
    leaveTrade: 'Вийти з торгу',
  },
};

export const en: typeof uk = {
  title: 'My trades',
  trade: 'Trade',
  columns: {
    trade: 'Trade',
    culture: 'Product',
    type: 'Type',
    info: 'Information',
    origin: 'Origin',
    company: 'Company',
    // country: 'Country',
    // openType: 'Initiator',
    status: 'Status',
    validUntil: 'Valid until',
    unread: '',
  },
  BadgeConfirmation: {
    closed: '{{company}}: Closed',
    nothing: '{{company}}',
    softOffer: `{{company}}: $t(${SCOPE_CHAT}$tradeTypes$softOffer)`,
    softRequest: `{{company}}: $t(${SCOPE_CHAT}$tradeTypes$softRequest)`,
    hardOffer: `{{company}}: $t(${SCOPE_CHAT}$tradeTypes$hardOffer)`,
    hardRequest: `{{company}}: $t(${SCOPE_CHAT}$tradeTypes$hardRequest)`,
  },
  initiatorType: {
    [InitiatorTypeEnum.got]: 'Received',
    [InitiatorTypeEnum.opened]: 'Opened',
  },
  statuses: {
    [TradeStatus.closed]: 'Closed',
    [TradeStatus.confirmed]: 'Confirmed',
    [TradeStatus.open]: '-',
  },
  filters: {
    all: 'All',
    open: 'Opened',
    received: 'Received',
    confirmed: 'Confirmed',
    closed: 'Closed',
    allCompanyTrades: 'All Company',
  },
  tooltips: {
    [InitiatorTypeEnum.got]: 'Received trade',
    [InitiatorTypeEnum.opened]: 'Created trade',
  },
  messages: {
    successClosed: 'Trade closed',
  },
  buttons: {
    leaveTrade: 'Leave the trade',
  },
};

export const ru: typeof uk = {
  title: 'Мои торги',
  trade: 'Торг',
  columns: {
    trade: 'Торг',
    culture: 'Продукт',
    type: 'Тип',
    info: 'Информация',
    origin: 'Происхождение',
    company: 'Компания',
    // country: 'Страна',
    // openType: 'Инициатор',
    status: 'Статус',
    validUntil: 'Действует до',
    unread: '',
  },
  BadgeConfirmation: {
    closed: '{{company}}: Закрыто',
    nothing: '{{company}}',
    softOffer: `{{company}}: $t(${SCOPE_CHAT}$tradeTypes$softOffer)`,
    softRequest: `{{company}}: $t(${SCOPE_CHAT}$tradeTypes$softRequest)`,
    hardOffer: `{{company}}: $t(${SCOPE_CHAT}$tradeTypes$hardOffer)`,
    hardRequest: `{{company}}: $t(${SCOPE_CHAT}$tradeTypes$hardRequest)`,
  },
  initiatorType: {
    [InitiatorTypeEnum.got]: 'Получен',
    [InitiatorTypeEnum.opened]: 'Открыт',
  },
  statuses: {
    [TradeStatus.closed]: 'Закрыт',
    [TradeStatus.confirmed]: 'Подтвержден',
    [TradeStatus.open]: '-',
  },
  filters: {
    all: 'Все',
    open: 'Открытые',
    received: 'Полученные',
    confirmed: 'Подтвержденные',
    closed: 'Закрытые',
    allCompanyTrades: 'Всей Компании',
  },
  tooltips: {
    [InitiatorTypeEnum.got]: 'Полученный торг',
    [InitiatorTypeEnum.opened]: 'Созданный торг',
  },
  messages: {
    successClosed: 'Торг закрыт',
  },
  buttons: {
    leaveTrade: 'Выйти из торга',
  },
};

export const bg: typeof uk = {
  title: 'Моите търгове',
  trade: 'Търг',
  columns: {
    trade: 'Търг',
    culture: 'Продукт',
    type: 'Тип',
    info: 'Информация',
    origin: 'Произход',
    company: 'Компания',
    // country: 'Страна',
    // openType: 'Инициатор',
    status: 'Състояние',
    validUntil: 'Валиден до',
    unread: '',
  },
  BadgeConfirmation: {
    closed: '{{company}}: Затворено',
    nothing: '{{company}}',
    softOffer: `{{company}}: $t(${SCOPE_CHAT}$tradeTypes$softOffer)`,
    softRequest: `{{company}}: $t(${SCOPE_CHAT}$tradeTypes$softRequest)`,
    hardOffer: `{{company}}: $t(${SCOPE_CHAT}$tradeTypes$hardOffer)`,
    hardRequest: `{{company}}: $t(${SCOPE_CHAT}$tradeTypes$hardRequest)`,
  },
  initiatorType: {
    [InitiatorTypeEnum.got]: 'Получен',
    [InitiatorTypeEnum.opened]: 'Отворен',
  },
  statuses: {
    [TradeStatus.closed]: 'Затворен',
    [TradeStatus.confirmed]: 'Потвърден',
    [TradeStatus.open]: '-',
  },
  filters: {
    all: 'Всички',
    open: 'Отворени',
    received: 'Получени',
    confirmed: 'Потвърдени',
    closed: 'Затворени',
    allCompanyTrades: 'Цялата Компания',
  },
  tooltips: {
    [InitiatorTypeEnum.got]: 'Получен търг',
    [InitiatorTypeEnum.opened]: 'Създаден търг',
  },
  messages: {
    successClosed: 'Търговията затворена',
  },
  buttons: {
    leaveTrade: 'Излезте от пазарлъка',
  },
};
