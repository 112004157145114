import { ITrade } from 'models/trade';

export const GET_ONE_TRADE_START = 'GET_ONE_TRADE_START';
export const GET_ONE_TRADE_SUCCESS = 'GET_ONE_TRADE_SUCCESS';
export const GET_ONE_TRADE_FAILURE = 'GET_ONE_TRADE_FAILURE';
export const CLEAR_ONE_TRADE = 'CLEAR_ONE_TRADE';
export const UPDATE_ONE_TRADE = 'UPDATE_ONE_TRADE';

export const getOneTradeSuccess = (payload: { results: ITrade[] }) => ({ type: GET_ONE_TRADE_SUCCESS, payload });
export const getOneTradeStarted = () => ({ type: GET_ONE_TRADE_START });
export const getOneTradeFailure = (error: string) => ({ type: GET_ONE_TRADE_FAILURE, payload: { error } });

export const clearOneTrade = () => ({ type: CLEAR_ONE_TRADE });

export const updateOneTrade = (payload: { data: Partial<ITrade>; tradeId: number; }) => ({ type: UPDATE_ONE_TRADE, payload });
