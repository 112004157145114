import React from 'react';
import { useT } from 'hooks';
import { SCOPE_REQUEST_LABEL } from 'i18n/_single/RequestLabel';
import './style.sass';

export enum TypesLabel {
  request = 'request',
  offer = 'offer',
  trade = 'trade',
}

interface Params {
  type: keyof typeof TypesLabel;
  isMy?: boolean | null;
}


const RequestLabel: React.FC<Params> = ({ type, isMy }) => {
  const _t = useT(SCOPE_REQUEST_LABEL);

  return (
    <div className="RequestLabel-root">
      {isMy &&
        <div className="type type-isMy">
          {_t.common('my')}
        </div>
      }
      <div className={`type type-${type}`}>
        {_t(type)}
      </div>
    </div>
  );
};

export default RequestLabel;
