import Nav from 'nav';
import { ModelBase } from 'models/_core/ModelBase';
import { ICompanyHandshake } from 'api/companies/details.api';
import { DateTimeHelper } from 'core/helpers/dateTime.helper';
import { FormatEnum } from 'constants/general';

export type { ICompanyHandshake };
export { Nav };

// TODO: Change to 48 hours
// const TIME_ACTION_PERIOD_MINUTES = 1 * 60;
const TIME_ACTION_PERIOD_MINUTES = 48 * 60;

export enum CompanyHandshakeSubStatus {
  none = 'none',
  my = 'my',
  opposite = 'opposite',
  full = 'full',
}

export class CompanyHandshake extends ModelBase<ICompanyHandshake> {
  static periodHours = Math.round(TIME_ACTION_PERIOD_MINUTES / 60);

  nextAction(format?: FormatEnum) {
    return DateTimeHelper.format(this.$.next_action_from, format);
  }

  get subStatusWithoutTime(): CompanyHandshakeSubStatus {
    return this.$.handshake
      ? this.$.target_handshake
        ? CompanyHandshakeSubStatus.full
        : CompanyHandshakeSubStatus.my
      : this.$.target_handshake
        ? CompanyHandshakeSubStatus.opposite
        : CompanyHandshakeSubStatus.none;
  }

  get subStatus(): CompanyHandshakeSubStatus {
    return this.$.handshake
      ? this.$.target_handshake
        ? CompanyHandshakeSubStatus.full
        : this.isBeforeTimeAction
          ? CompanyHandshakeSubStatus.my
          : CompanyHandshakeSubStatus.none
      : this.$.target_handshake
        ? this.isBeforeTimeAction
          ? CompanyHandshakeSubStatus.opposite
          : CompanyHandshakeSubStatus.none
        : CompanyHandshakeSubStatus.none;
  }

  get realValue(): boolean {
    return this.$.handshake
      ? this.$.target_handshake
        ? true
        : this.isBeforeTimeAction
          ? true
          : false
      : this.$.target_handshake
        ? this.isBeforeTimeAction
          ? false
          : false
        : false;
  }

  get on(): boolean {
    return this.$.handshake && this.$.target_handshake;
  }

  get canBeChanged(): boolean {
    return this.isWaitingMyApprove ? true : this.isAfterTimeAction;
  }

  get isWaitingMyApprove(): boolean {
    return !this.$.handshake && this.$.target_handshake;
  }

  get isBeforeTimeAction(): boolean {
    return DateTimeHelper.isFuture(this.$.next_action_from);
  }
  get isAfterTimeAction(): boolean {
    return !DateTimeHelper.isFuture(this.$.next_action_from);
  }

  get elapcedTime(): string {
    return DateTimeHelper.format(this.$.next_action_from, FormatEnum.fromNow);
  }
  get percentElapcedToNextAction(): number {
    if (!this.$.next_action_from) {
      return 100;
    }
    const diffInMilliseconds = new Date(this.$.next_action_from).getTime() - new Date().getTime();
    const diffInMinutes = diffInMilliseconds / (1000 * 60);
    const percentageRemaining = (diffInMinutes / TIME_ACTION_PERIOD_MINUTES) * 100;
    return 100 - Math.max(0, Math.min(percentageRemaining, 100));
  }


  get path(): string {
    return '';
  }
}
