import { TypesLabel } from 'components/RequestLabel';

export const SCOPE_REQUEST_LABEL = 'RequestLabel';

export const uk = {
  [TypesLabel.request]: 'Покупка',
  [TypesLabel.offer]: 'Продаж',
  [TypesLabel.trade]: 'Торг',
};

export const en: typeof uk = {
  [TypesLabel.request]: 'Buy',
  [TypesLabel.offer]: 'Sell',
  [TypesLabel.trade]: 'Trade',
};

export const ru: typeof uk = {
  [TypesLabel.request]: 'Покупка',
  [TypesLabel.offer]: 'Продажа',
  [TypesLabel.trade]: 'Торг',
};

export const bg: typeof uk = {
  [TypesLabel.request]: 'Покупка',
  [TypesLabel.offer]: 'Продажба',
  [TypesLabel.trade]: 'Търг',
};
