import { uk as OldFields } from 'i18n/uk';
import { uk as CommonFields } from 'i18n/_single/common';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';

export { i18n };

export type TArgs = {
  scope?: string | string[];
  notUseScope?: boolean;
  subScope?: string | string[];
  to?: string;
} & Record<string, unknown>;

type pureI18nFun<ScopeType> = (
  text: ScopeType,
  tArgs?: TArgs,
) => string;

export type SimpleT = (text: string | string[], args?: Record<string, unknown>) => string;

export type T<ScopeType = string | string[]> = pureI18nFun<ScopeType>
& {
  common: (text: keyof typeof CommonFields, args?: Record<string, unknown>) => string;
  old: (text: keyof typeof OldFields, args?: Record<string, unknown>) => string;
  scope: (scope: string | string[]) => SimpleT;
  subScope: (subScope: string | string[]) => SimpleT;
};



export const useT = <ScopeType = string | string[]>(scope: string | string[] = ''): T<ScopeType> => {
  const { t } = useTranslation();
  const cb: T<ScopeType> = function (text, { scope: _scope, notUseScope, subScope, ...args } = {}) {
    return t(
      [[!notUseScope && (_scope || scope), subScope, text].filter(Boolean).flat().join('.').replace(/\./g, '$'), [text].flat().join('.')],
      args,
    );
  };
  cb.common = (text, args) => t(text, args);
  cb.old = (text, args) => t(text, args);
  cb.scope = (scope) => (text, args) => t([scope, text].flat().join('$'), args);
  cb.subScope = (subScope) => (text: string | string[], args) => {
    return t([scope, subScope, text].flat().join('$'), args);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const translate = useCallback<T<ScopeType>>(cb, [t]);
  return translate;
};
