import moment from 'moment';
import i18n from 'i18n';
import { FormatEnum } from 'constants/general';

type TFormat = 'common' | 'dates';
type DateArgumentType = moment.MomentInput;

const FormatsEnum: Record<TFormat, FormatEnum[]> = {
  common: [FormatEnum.time24, FormatEnum.simpleDatetime, FormatEnum.full, FormatEnum.fromNow],
  dates: [FormatEnum.main, FormatEnum.dayMonth, FormatEnum.fromNow],
};

export class DateTimeHelper {
  static format(date: DateArgumentType, format = FormatEnum.main): string {
    if (format === FormatEnum.fromNow) {
      return moment(date).fromNow();
    }
    return moment(date).format(format);
  }
  static cyclingFormat(date: string | Date, formatIndex = 0, formatType: TFormat = 'common'): string {
    const format = FormatsEnum[formatType][formatIndex % FormatsEnum[formatType].length];
    return this.format(date, format);
  }
  static isFuture(date: DateArgumentType): boolean {
    return moment(date).isAfter();
  }
  static formatPeriodDates(dateFrom?: string | Date, dateTo?: string | Date, format?: FormatEnum): string {
    const startDate = dateFrom && moment(dateFrom);
    const endDate = dateTo && moment(dateTo);
    const formatDateFrom = startDate && startDate.format(format || 'D MMM');
    const formatDateTo = endDate && endDate.format(format || 'D MMM YYYY');
    return [formatDateFrom, formatDateTo].filter(Boolean).join(' - ');
  }
  static getDiffDurationDate(endData?: string, unitOfTime?: moment.unitOfTime.Diff | undefined): number {
    const now = moment(new Date());
    const expiration = endData ? moment(endData) : now;
    return expiration.diff(now, unitOfTime);
  }
  static timeLeft(endDate: string | Date | moment.Moment): string {
    const now = moment();
    const target = moment(endDate);

    const days = target.diff(now, 'days');
    const hours = target.diff(now, 'hours') % 24;
    const minutes = target.diff(now, 'minutes') % 60;

    if (days > 0 || hours > 22 || minutes < 0) {
      return i18n.t('day', { count: Math.max(days, 0) });
    }

    return `${hours}:${minutes}`;
  }
}
