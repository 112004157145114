import React from 'react';
import SvgIconContainer from '../SvgIconContainer';

export const SvgTwoArrowsIcon: React.FC<{ className?: string; }> = ({ className = '' }) => {
  return (
    <SvgIconContainer classes={className}>
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="2.55266" y1="13.3407" x2="11.886" y2="4.00741" stroke="currentColor" strokeWidth="2"/>
        <path d="M12.5 10.667L12.5 2.66699L4.5 2.66699" stroke="currentColor" strokeWidth="2"/>
        <line x1="22.7012" y1="10.9171" x2="13.3679" y2="20.2504" stroke="currentColor" strokeWidth="2"/>
        <path d="M12.498 13.333L12.498 21.333L20.498 21.333" stroke="currentColor" strokeWidth="2"/>
      </svg>
    </SvgIconContainer>
  );
};
