import React, { useEffect, useLayoutEffect } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { Router, useLocation } from 'react-router-dom';
import i18n, { Language } from 'i18n';
import moment from 'moment';
import 'moment/locale/uk';
import 'moment/locale/ru';
import 'moment/locale/bg';
import MomentUtils from '@date-io/moment';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { themeConfig } from './App.constants';
import { TagManagerService } from 'services/dataLayer.service';
import Nav, { initHistory } from 'nav';
import { getAll } from 'actions/allActions';
import { setReferralData } from 'reducers/slices/systemSettings.slice';
import { getUser, useTsDispatch, useTsSelector } from 'reducers/profile/user.slice';
import { Root } from './Root';
import './App.scss';


const theme = createTheme(themeConfig);

function ScrollToTop() {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    document.getElementsByTagName('main')[0]?.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default function App() {

  const location = window.location;
  const pathLanguage = location.pathname.split('/')[1];
  const user = useTsSelector(({ profile }) => profile.user);

  const languageFromPath = Language.findByPathOrDefault(pathLanguage);

  const all = useTsSelector(state => state.all);
  // const storeLanguageKey = useTsSelector(state => state.language);
  const dispatch = useTsDispatch();


  useEffect(() => {
    // const storeLanguage = Language.list[storeLanguageKey];
    if (!languageFromPath.isCurrent) {
      i18n.changeLanguage(languageFromPath.key).then(() => {
        all.loaded && dispatch(getAll(true));
      });
    }
    moment.locale(languageFromPath.key);
    moment.updateLocale(languageFromPath.key, { week: { dow: 1 } });
  }, [languageFromPath.key]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => { dispatch(setReferralData()); }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => { dispatch(getUser()); }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => { TagManagerService.updateAuth(user.id); }, [user.id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (languageFromPath.path !== pathLanguage) {
    Nav.changeLanguage(languageFromPath);
    return null;
  }

  const history = initHistory(languageFromPath);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router history={history}>
            <ScrollToTop />
            <Root />
            {/* <Route path="/:language?" component={Root} /> */}
          </Router>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </GoogleOAuthProvider>
  );
}
